import { createSlice } from '@reduxjs/toolkit';
import { filterTime } from '../../utility/filterUtils';

const SiteSettingsSlice = createSlice({
  name: 'menuType',
  initialState: {
    menuType: 'Recreational', // Default value
    activeDiscounts: [],
  },
  reducers: {
    toggleMenuType: (state) => {
      state.menuType = state.menuType === 'Recreational' ? 'Medical' : 'Recreational';
    },
    changeMenuType: (state, action) => {
      state.menuType = action.payload;
    },
    setActiveDiscounts: (state, action) => {
      state.activeDiscounts = filterTime(action.payload)
    },
  },
});

export const { toggleMenuType, changeMenuType } = SiteSettingsSlice.actions;
export const selectMenuType = (state) => state.menuType.menuType;
export default SiteSettingsSlice.reducer;
export const { setActiveDiscounts } = SiteSettingsSlice.actions;
