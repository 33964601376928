import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSelector } from "react-redux";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';

import { Pagination, Autoplay } from 'swiper/modules';
import { isMobile } from "react-device-detect";
import { filterTime } from "../utility/filterUtils";
import { notEmpty, notNull } from "../sharedFunctions";
import { Skeleton } from "antd";

const CarouselBanner = ({component}) => {
  const [isLoading] = useState(false);
  const [error] = useState(null);
  const discounts = useSelector((state) => state?.siteSettings?.activeDiscounts);
  const [ready, setReady] = useState(false);
  const { auto_play_interval, slides } = component || {};
  const [filteredSlides, setFilteredSlides] = useState([]);

  useEffect(() => {
    if(notNull(slides)){
      const activeDiscountIds = discounts?.map(discount => discount?.id);
      const normalSlides = filterTime(slides.filter(slide => !slide?.sync_with_discount))
      const slidesWithDiscount = slides.filter(slide => slide?.sync_with_discount);
      const discountSlides = slides.filter(slide => slide?.sync_with_discount && activeDiscountIds.includes(slide?.discount_id));
      console.log({normalSlides, discountSlides, slidesWithDiscount})
      setFilteredSlides(normalSlides.concat(discountSlides));
      setReady(true);
    }
  }, [slides, discounts]);


  const contentData = useSelector((state) => state?.content?.contentData?.components.filter((c) => c.type === "carousel")[0]);

  if (isLoading) {
    return <img className="mx-auto d-block" src="./images/loading.gif" alt="Loading..." />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!contentData) {
    // Handle the case where no content data is available
    return <p>No data found</p>;
  }

  const navigateSlide = (slide) => {
    if (slide?.url) {
      window.location.href = slide.url;
    }
  }

  // {
  //   "name": "Happy Hour",
  //   "file": {
  //     "url": "https://pbit-qa.s3.amazonaws.com/menu/carousel_slide/9/Artboard_6.png"
  //   },
  //   "url": "",
  //   "sync_with_discount": false,
  //   "discount_id": null,
  //   "start_date": null,
  //   "end_date": null,
  //   "days": [
  //     ""
  //   ],
  //   "start_at": null,
  //   "end_at": null
  // }

  return (
    <section>
      <Swiper
        style={{maxWidth: 1600, margin: isMobile ? 8 : 'auto', borderRadius: isMobile ? 10 : '25px'}}
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        autoplay={{ delay: auto_play_interval * 1000 }}
        modules={[Pagination, Autoplay]}
        pagination={{
          clickable: true
        }}
      >
        {ready ?
          filteredSlides?.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={slide?.file?.url}
                  className="d-block w-100"
                  alt="..."
                  onClick={() => navigateSlide(slide)}
                />
              </SwiperSlide>
            )

          })
          :
          <SwiperSlide>
            <Skeleton.Image active  style={{width: '100%'}}/>
          </SwiperSlide>
        }

      </Swiper>
    </section>
  );


}

export default CarouselBanner;
